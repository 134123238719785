import React, { useEffect, useState } from 'react';
import './Landing.css';
import { features } from './features';
import Register from '../Register';
import Login from '../Login';
import { api } from '../../helpers/urls';
import Carousel from 'react-elastic-carousel';

function Landing(props) {
	const initialState = {
		slideIdx: features.features.map((f) => 0),
	};

	const [state, setState] = useState({ ...initialState });
	const [policyUrls, setPolicyUrls] = React.useState({
		TOS: '',
		privacyPolicy: '',
	});
	const [activeForm, setActiveForm] = React.useState('Register');

	const incrementSlide = (state) => {
		const { slideIdx } = state;
		return slideIdx.map((slideIdx, idx) => {
			let slideArr = features.features[idx].imgs.slice();
			let slideLen = slideArr.length;
			let newIdx = slideIdx + 1 >= slideLen ? 0 : slideIdx + 1;
			return newIdx;
		});
	};
	async function fetchAppConfig() {
		fetch(
			api.appConfig,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			},
			10000
		)
			.then((response) => response.json())
			.then(
				(res) => {
					if (res.errorMessage) {
						//do nothing for now
					} else {
						setPolicyUrls(res);
					}
				},
				(err) => {
					console.log(err);
					//do nothing for now
				}
			);
	}

	useEffect(() => {
		fetchAppConfig();
		setInterval(() => {
			setState((prevState) => {
				return { slideIdx: incrementSlide(prevState) };
			});
		}, 5000);
	}, []);

	return (
		<div className='landing-page'>
			<div className='header'>
				<img className='brand-logo' src={features.header.logo} />
				<div className='btn-container'>
				<div>
				<a
					href='#signin'
					className='signin-btn'
					onClick={() => setActiveForm('Login')}
				>
					Sign In
				</a>
				</div>
				<div >
				<a
					href='#signup'
					className='login-btn'
					onClick={() => setActiveForm('Register')}
				>
					Sign up
				</a>
				</div>
			</div>
			</div>
			<div className='hero-section'>
				<div className='hero-img-container'>
					<img
						className='hero-img'
						src={features.features[0].imgs[state.slideIdx[0]].src}
					/>
				</div>
				<div className='hero-details'>
					<img src={features.header.logo} className='hero-logo' />
					<p className='heading'>{features.heroSection.heading}</p>
					{features.heroSection.subHeadings.map((subHeading) => {
						return <p className='sub-heading'>{subHeading}</p>;
					})}
					{/* <button className='btn trial-cta'> */}
					<a
						href='#signup'
						className='btn trial-cta'
						onClick={() => setActiveForm('Register')}
					>
						{features.heroSection.CTA}
					</a>
					<a
						href='#signin'
						className='login-url'
						onClick={() => setActiveForm('Login')}
					>
						{features.heroSection.login}
					</a>
					{/* </button> */}
				</div>
			</div>
			<div className='features-wrapper'>
				<div className='features-section'>
					{features.features.map((feature) => {
						let cardItemsDOM = [],
							cardsDOM = [],
							badgeItemsDOM = [],
							badgesDOM = [],
							buttonsDOM = [];

						const { orientation } = feature;

						// hero section
						if (feature.id === 0) {
							return;
						}

						// store cards dom
						if (feature.id === 1) {
							cardItemsDOM = feature.cards.map((card) => {
								return (
									<div className='card'>
										<img
											className='card-img'
											src={card.img}
										/>
										<p className='heading'>
											{card.text}{' '}
											<span className='sub-text'>
												{card.subText}
											</span>
										</p>
										{card.items.map((item) => {
											return (
												<p className='item-text'>
													{item.text}
												</p>
											);
										})}
									</div>
								);
							});

							cardsDOM = (
								<div className='cards'>{cardItemsDOM}</div>
							);
						}

						// demo store btn
						if (feature.id === 1) {
							buttonsDOM = (
								<div className='demo-store'>
									<img
										src={feature.buttons.demoStore.icon}
										className='store-icon'
									/>
									<a
										className='btn demo-store-btn'
										href={feature.buttons.demoStore.href}
										target='_blank'
									>
										{feature.buttons.demoStore.text}
									</a>
								</div>
							);
						}

						// KMS Intel badges dom
						if (feature.id === 4) {
							badgeItemsDOM = feature.badges.map((badge) => {
								return (
									<div className='badge'>
										<img
											className='badge-img'
											id={`badge${badge.id}`}
											src={badge.img}
											alt={badge.title}
										/>
									</div>
								);
							});
							badgesDOM = (
								<div
									className='badges-wrapper'
									id='powered-by-intel'
								>
									{/* <p className='heading'>Powered by Intel&reg;</p> */}
									<div className='badges'>
										{badgeItemsDOM}
									</div>
								</div>
							);
						}

						// powered by UY
						if (feature.id === 7) {
							badgeItemsDOM = feature.badges.map((badge) => {
								return (
									<div className='badge'>
										<a
											href='https://www.udyogyantra.com/'
											target='_blank'
										>
											<img
												className='badge-img'
												src={badge.img}
												alt={badge.title}
											/>
										</a>
									</div>
								);
							});
							badgesDOM = (
								<div
									className='badges-wrapper'
									id='powered-by-uy'
								>
									<div className='badges'>
										{badgeItemsDOM}
									</div>
								</div>
							);
						}
						return (
							<div className={`feature ${orientation}`}>
								<div className='feature-container'>
									<div className='feature-details'>
										<p className='feature-heading'>
											{feature.heading}
										</p>
										{feature.subHeadings.map(
											(subHeading) => {
												return (
													<p className='sub-heading'>
														{subHeading}
													</p>
												);
											}
										)}
										{buttonsDOM}
									</div>
									<div className='feature-img-container'>
										{feature.imgs.length > 0 && (
											<img
												className='feature-img'
												src={
													feature.imgs[
														state.slideIdx[
															feature.id
														]
													].src
												}
											/>
										)}
									</div>
								</div>
								{cardsDOM}
								{badgesDOM}
							</div>
						);
					})}
				</div>
			</div>
				<div className='signup-wrapper' >
                    <div id='signup' style={{
						visibility: activeForm==='Register'?'visible':'hidden'
					}}>
					<Register
						policyUrls={policyUrls}
						setActiveForm={setActiveForm}
					/>
                    </div>
                    <div id='signin' className='signin' style={{
						visibility: activeForm!=='Register'?'visible':'hidden'
					}}>
                    <Login
                        policyUrls={policyUrls}
                        setActiveForm={setActiveForm}
                    />
                </div>
				</div>
			
			<div className='news-wrapper'>
				<p className='heading'>{features.news.heading}</p>
				<Carousel itemsToShow={1}>
					{features.news.newsItems.map((item) => {
						return (
							<div className='news-item-img-container'>
								<img src={item.img} className='news-item-img' />
							</div>
						);
					})}
				</Carousel>
			</div>
			<div className='footer-wrapper'>
				<div className='footer footer-details'>
					<div className='about-us'>
						<p className='heading'>About Us</p>
						<p className='description'>
							{features.footer.aboutUs.description}
						</p>
					</div>
					<div className='contact-us'>
						<p className='heading'>Contact Us</p>
						<div className='addresses'>
							{features.footer.contactUs.addresses.map(
								(address) => {
									return (
										<div className='address'>
											<p className='region'>
												{address.region}
											</p>
											<p>{address.address}</p>
											<p>Phone: {address.phone}</p>
										</div>
									);
								}
							)}
						</div>
						<p>Email: {features.footer.contactUs.email}</p>
					</div>
				</div>
				<div className='footer footer-copy'>
					<p>
						&copy; {new Date().getFullYear()} Udyogyantra - All
						rights reserved
					</p>
					<div className='policy-urls'>
						<a href={policyUrls.TOS} target='_blank'>
							Terms of Service
						</a>
						<a href={policyUrls.privacyPolicy} target='_blank'>
							Privacy Policy
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Landing;
