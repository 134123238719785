import resoeeLogo from '../../images/Landing/Resoee-removebg.png';

import hero1 from '../../images/Landing/hero1.png';
import hero2 from '../../images/Landing/hero2.png';
import hero3 from '../../images/Landing/hero3.png';
// import hero5 from '../../images/Landing/hero5.png';
import hero6 from '../../images/Landing/hero6.png';
import hero7 from '../../images/Landing/hero7.png';
import hero8 from '../../images/Landing/hero8.png';
import hero9 from '../../images/Landing/hero9.png';

import profits from '../../images/Landing/profits.svg';
import customers from '../../images/Landing/customers.svg';

import intelGold1 from '../../images/Landing/intel-gold-1.png';
import intelGold2 from '../../images/Landing/intel-gold-2.png';
import nasscom from '../../images/Landing/nasscom.png';
import patent from '../../images/Landing/patent.png';

import feature1_1 from '../../images/Landing/feature1_1.png';
import feature1_2 from '../../images/Landing/feature1_2.png';
import feature1_3 from '../../images/Landing/feature1_3.png';
import feature1_4 from '../../images/Landing/feature1_4.png';
import feature1_5 from '../../images/Landing/feature1_5.png';
import feature1_6 from '../../images/Landing/feature1_6.png';
import feature1_7 from '../../images/Landing/feature1_7.png';
import feature1_8 from '../../images/Landing/feature1_8.png';

import demoStore from '../../images/Landing/store.png';

import feature2_1 from '../../images/Landing/feature2_1.png';
import feature2_2 from '../../images/Landing/feature2_2.png';
import feature2_3 from '../../images/Landing/feature2_3.png';
import feature2_4 from '../../images/Landing/feature2_4.png';

import feature3_1 from '../../images/Landing/feature3_1.png';
import feature3_2 from '../../images/Landing/feature3_2.png';

import feature4_1 from '../../images/Landing/feature4_1.png';
import feature4_2 from '../../images/Landing/feature4_2.png';
import feature4_3 from '../../images/Landing/feature4_3.png';
import feature4_4 from '../../images/Landing/feature4_4.png';
import feature4_5 from '../../images/Landing/feature4_5.png';
import feature4_6 from '../../images/Landing/feature4_6.png';

import feature5_1 from '../../images/Landing/feature5_1.png';

import feature6_1 from '../../images/Landing/feature6_1.png';

import feature7_1 from '../../images/Landing/feature7_1.png';
import feature7_2 from '../../images/Landing/feature7_2.png';
import feature7_3 from '../../images/Landing/feature7_3.png';
import uyLogo from '../../images/Landing/UY_logo.png';

import news1 from '../../images/Landing/news1.png';
import news2 from '../../images/Landing/news2.png';
import news3 from '../../images/Landing/news3.png';
import news4 from '../../images/Landing/news4.png';
import news5 from '../../images/Landing/news5.png';
import news6 from '../../images/Landing/news6.png';
import news7 from '../../images/Landing/news7.png';

export const features = {
    header: {
        logo: resoeeLogo,
    },
    heroSection: {
        heading: 'The Online Restaurant Platform',
        subHeadings: [
            'Enabling Digital Transformation of Restaurants.',
            'Create your own restaurant in a day !',
        ],
        CTA: 'Request a Demo',
        login: 'Already Registered?'
    },
    featureHeading: '',
    features: [
        {
            id: 0,
            heading: 'The Online Restaurant Platform',
            subHeadings: [
                'Enabling Digital Transformation of Restaurants.',
                'Create your own restaurant in a day !',
            ],
            imgs: [
                {
                    src: hero1
                },
                {
                    src: hero2
                },
                {
                    src: hero3
                },
                {
                    src: hero6
                },
                {
                    src: hero7
                },
                {
                    src: hero8
                },
                {
                    src: hero9
                }
            ],
        },
        {
            id: 1,
            heading: 'Your Own Online Restaurant',
            subHeadings: [
                'A parallel channel in addition to aggregators',
            ],
            cards: [
                {
                    text: 'Make more Profits',
                    subText: 'by',
                    img: profits,
                    items: [
                        {
                            text: 'Doing Direct Food Delivery',
                        },
                        {
                            text: 'Building Differentiated Experiences in Food Delivery'
                        }
                    ]

                },
                {
                    text: 'Build Direct Relationship with Customers',
                    subText: 'creating',
                    img: customers,
                    items: [
                        {
                            text: 'Loyal Customers',
                        },
                        {
                            text: 'Reduced Dependency on Discounts for Promotions'
                        }
                    ]

                },
            ],
            imgs: [
                {
                    src: feature1_1
                },
                {
                    src: feature1_2
                },
                {
                    src: feature1_3
                },
                {
                    src: feature1_4
                },
                {
                    src: feature1_5
                },
                {
                    src: feature1_6
                },
                {
                    src: feature1_7
                },
                {
                    src: feature1_8
                }
            ],
            orientation: 'portrait',
            buttons: {
                demoStore: {
                    text: 'Explore a Sample "Internet Restaurant" on RESOEE',
                    icon: demoStore,
                    href: 'https://demo7.resoee.com/store'
                }
            }
        },
        {
            id: 2,
            heading: 'Build, Maintain and Evolve your restaurants – On your own.',
            subHeadings: [
                'Central Console for Restaurant to Manage its own restaurant – No dependency on any IT Engineer',
                'Automated Cancellation handling and Refunds',
                'Revenue Reports'
            ],
            imgs: [
                {
                    src: feature2_1
                },
                {
                    src: feature2_2
                },
                {
                    src: feature2_3
                },
                {
                    src: feature2_4
                }
            ],
            orientation: 'landscape'
        },
        {
            id: 3,
            heading: 'Get back Control of your Online Restaurant',
            subHeadings: [
                'Make your own PROMO Codes',
                'Automatic Scheduling of Items on Menu',
                'Build in Suggestions of “Add On”',
                'Live SMS and Email Order Updates to your customers',
                'Live Cooking Tracking (optional)'
            ],
            imgs: [
                {
                    src: feature3_1
                },
                {
                    src: feature3_2
                }
            ],
            orientation: 'landscape'
        },
        {
            id: 4,
            heading: 'Tightly Integrated Kitchen Management ,  powered by Patented “Smart Food QC”',
            subHeadings: [
                'An Artificial Intelligence and IoT enabled Kitchen Management System',
                'Designed with Future of Restaurants in focus – The Internet Restaurant Platform',
                'Perfect for Digital Transformation of Restaurants, Delivery Focused Restaurants, Virtual Restaurants, Ghost Kitchens & Cloud Kitchens',
            ],
            badges: [
                {
                    id: 1,
                    title: 'Intel Gold Partner',
                    img: intelGold1,
                    subText: '',
                },
                {
                    id: 2,
                    title: 'Intel IoT Gold Partner',
                    img: intelGold2,
                    subText: '',
                },
                {
                    id: 3,
                    title: 'Patented Technology',
                    img: patent,
                    subText: '',
                },
                {
                    id: 4,
                    title: 'NASSCOM',
                    img: nasscom,
                    subText: '',
                }
            ],
            imgs: [
                {
                    src: feature4_1
                },
                {
                    src: feature4_2
                },
                {
                    src: feature4_3
                },
                {
                    src: feature4_4
                },
                {
                    src: feature4_5
                },
                {
                    src: feature4_6
                }
            ],
            orientation: 'landscape'
        },
        {
            id: 5,
            heading: 'Quality Management System',
            subHeadings: [
                'Next Generation AI Based',
                'Prepared Food Quality & Quantity Authentication & Dispatch Control'
            ],
            imgs: [
                {
                    src: feature5_1
                }
            ],
            orientation: 'landscape'
        },
        {
            id: 6,
            heading: 'Inventory Management System',
            subHeadings: [
                'Next Generation AI Based – Inventory Tagging, Quality & Quantity Authentication & LIVE Inventory Control of Kitchen Supplies '
            ],
            imgs: [
                {
                    src: feature6_1
                }
            ],
            orientation: 'landscape'
        },
        {
            id: 7,
            heading: 'Cooking Management System',
            subHeadings: [
                'Cook Each Dish Consistently – Each Time & Every Time',
                'All Ingredients, Correct Ingredients',
                'Real Time Stock Updates including Wastage Tracking while Cooking '
            ],
            imgs: [
                {
                    src: feature7_1
                },
                {
                    src: feature7_2
                },
                {
                    src: feature7_3
                }
            ],
            badges: [
                {
                    img: uyLogo,
                    title: 'powered by Udyogyantra'
                }
            ],
            orientation: 'landscape'
        }
    ],
    news: {
        heading: 'In the News',
        newsItems: [
            {
                title: '',
                img: news5,
            },
            {
                title: '',
                img: news6,
            },
            {
                title: '',
                img: news7,
            },
            {
                title: '',
                img: news1,
            },
            {
                title: '',
                img: news2,
            },
            {
                title: '',
                img: news3,
            },

        ]
    },
    footer: {
        aboutUs: {
            description: `UdyogYantra Technologies are inventors and builders of an AI & IoT powered Internet Restaurant Platform that ensures digitization with authentication in the future of food.
            We enable food businesses to Setup Online Internet Restaurants and go LIVE in 1 Business Day. We Also Provide Complete Kitchen Management System for the Cloud Kitchens, Tightly Integrated Enterprise Solution, Recipe Execution Standardization, Standardization of Food Preparation Process ,Authentication of Quality and Quantity and many more. We enable Dashboard and Data Analytics for Food Enterprise to view the adherence to Quality, Defined Food Preparation Processes, Ingredients across distributed network of kitchens across multiple business models.`
        },
        contactUs: {
            addresses: [
                {
                    region: 'India',
                    address: '505, Jyoti Shikhar Building, District Center Janakpuri, New Delhi 110058',
                    phone: '+91-995-886-3566',
                },
                {
                    region: 'North America',
                    address: '1102, 260 Doris Avenue, M2N6X9, Toronto, Canada',
                    phone: '+1-416-458-6654'
                }
            ],
            email: 'info@udyogyantra.com'
        }
    }
};
